
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>私域流量运营</h3>
      <p>
        私域流量是企业可以有效和主动触达的客户群体，本质是高质量的营销触达和客户关系管理。在获客成本日益高企的流量去中心化时代，越来越多的品牌开始意识到私域流量的DTC运营模式对于获客、留存和转化的重要性。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <img src="../../assets/images/ll01.png" />
      <div>
        <h6>微心动私域流量运营解决方案</h6>
        <p>
          私域流量不仅仅存在于传统意义上的微信体系，而是存在于可与客户触达的所有渠道平台，分散在小程序、各类会员系统、支付宝生活号、自媒体号、线下数据表、SCRM客户管理系统等渠道。<br />
          微心动私域流量运营解决方案，具备跨平台的流量整合及应用能力，能够统一管理全渠道私域用户的营销互动，并具有企业级数据安全和个人信息保护机制。
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="content_1">
        <img src="../../assets/images/llhx.png" alt="">
    </div>
    <div class="content_2">
      <div>
        <h6>全链路营销·私域流量的破圈运营</h6>
        <p>
          全链路闭环: 打通从投放到营销全流程再到交易环节，公域+私域的完整营销链路
          私域流量池扩容: 通过全渠道数据接入和丰富的扩展能力，对流量池进行持续扩容
          数据流转: 建立CDP客户数据平台，进行用户画像和洞察，形成精准DMP人群包
          重定向广告: 通过实时重定向引擎实现私域事件自动触发Retargeting广告重定向
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/ll02.png" />
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onapply(){
        // this.childEvent(true);
        window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
      }
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization02.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 7.32rem;
    padding-left: 0.88rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      padding-right: 0.65rem;
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.content_1 {
    text-align: center;
    img{
        width: 14.4rem;
        height: 5.64rem;

    }
}
.content_2 {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 5.79rem;
    padding-left: 0.58rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
      padding-right: 0.1rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
